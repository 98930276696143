@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  margin: 0px;
  padding: 0px;
  font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.error {
  color: #ff0000;
  font-size: 13px;
}
.wrapper-1 {
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
}
.section-1-wrap {
  display: flex;
  width: 100%;
}
.text-center {
  text-align: center;
}
.carousel-caption h3 {
  font-weight: 400;
  font-size: 50px;
  font-family: "Dosis", sans-serif;
}
img {
  max-width: 100%;
  height: auto;
}
.inner-banner {
  margin: 120px 0px 0px 0px;
  padding: 20px 0px;
  background-image: url(./images/inner-banner-bg.png);
  background-size: 100% 100%;
  text-align: center;
  min-height: 250px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.inner-banner h1 {
  font-family: "Dosis", sans-serif;
  color: #0480c4;
  font-size: 50px;
  font-weight: 400;
  margin: 20px 0px;
  padding: 0px;
}
.inner-banner p {
  line-height: 24px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 10px 0px;
  padding: 0px;
}
.inner-banner-text {
  max-width: 80%;
  margin: auto;
}

.inner-section-1-wrap {
  margin: 0px 0px 0px 0px;
  padding: 20px 0px;
  background-size: 100% 100%;
  text-align: center;
  /* min-height: 250px; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.inner-section-1-wrap h1 {
  font-family: "Dosis", sans-serif;
  color: #000;
  font-size: 50px;
  font-weight: 400;
  margin: 20px 0px;
  padding: 0px;
}
.inner-section-1-wrap h2 {
  font-family: "Dosis", sans-serif;
  color: #999;
  font-size: 50px;
  font-weight: 400;
  margin: 20px 0px;
  padding: 0px;
}
.inner-section-1-wrap p {
  line-height: 24px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 10px 0px;
  padding: 0px;
}
.wrap-1 {
  width: 80%;
  margin: 0 auto;
}

.inner-section-2-bg {
  background-color: #e6f6ff;
  padding: 50px 0px;
}
.services-card2-section {
}
.services-card2-1 {
  background-color: #fff;
  padding: 20px 20px 20px 40px;
  width: 70%;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
  border-left: 6px solid;
  border-color: #0480c4 !important;
  margin-bottom: 50px;
}
.services-card2-1 h3 {
  font-family: "Dosis", sans-serif;
  font-size: 30px;
  font-weight: 400;
  margin: 14px 0px;
  padding: 0px;
}
.services-card2-1 p {
  line-height: 24px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 10px 0px 20px 0px;
  padding: 0px;
}

.services-card2-section .services-card2-1:nth-child(even) {
  margin-left: 20%;
  background-color: #0480c4;
  border-color: #fff !important;
  color: #fff;
}
.services-card2-section .services-card2-1:nth-child(even) a {
  border-color: #fff;
  color: #fff;
}
.three-col-main {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.three-col-1 {
  width: 28%;
}
.text-left {
  text-align: left !important;
}
.two-col-main {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
}
.two-col-1 {
  width: 40%;
  margin-bottom: 40px;
}
.card-sec h3 {
  font-size: 26px;
  margin: 14px 0px;
  font-family: "Dosis", sans-serif;
}
.card-sec p {
  color: #000 !important;
  font-family: "Poppins", sans-serif;
}
.card-sec img {
  width: 100%;
  height: auto;
  padding: 10px;
  /* border: 1px solid #e3e3e3; */
  background-color: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}
.line-3 {
  margin: 0px 0px 0px 0px;
  height: 1px;
  background-color: #a4a4a4;
  width: 100%;
}
.button-line {
  padding: 3px 0px;
  font-size: 20px;
  display: inline-block;
  border: 0px;
  color: #0077b5;
  text-decoration: none;
  cursor: pointer;
}
.our-vendor-relationship-form {
  background-color: #e6f6ff;
  padding: 20px;
  margin: 25px 0px;
}
.our-vendor-relationship-form-flex {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.ff-1 {
  width: 47%;
  margin: 14px 0px;
}
.ff-1 input,
.ff-1 textarea {
  width: 100%;
  padding: 10px 10px;
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  resize: none;
}
.ff-1 textarea {
  height: 120px;
}
.ff-1 b {
  color: #000 !important;
  text-align: left;
  display: block !important;
  padding-bottom: 5px !important;
}

.text-left {
  text-align: left !important;
}
.ff-1 b {
  margin: 0px;
  padding: 0px;
  color: #0077b5;
  font-weight: 400;
}

.contact-form-bg {
  background-color: #fff;
  padding: 20px;
  margin: 25px 0px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
}
.contact-form-bg .ff-1 input,
.contact-form-bg .ff-1 textarea {
  background-color: #ededed;
  color: #000;
  border: 0px solid #000;
}
.contact-form-bg h2 {
  color: #000;
  font-size: 30px !important;
  margin: 0px 0px 14px 0px;
}

.contact-location-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.contact-location-1 {
  display: flex;
  width: 30%;
  margin-bottom: 20px;
}
.contact-location-1-full {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.contact-flex-wrap {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.contact-flex-1 {
  width: 45%;
  padding-right: 10%;
}

.contact-location-1-1 {
  width: 50px;
}
.contact-location-1-1 img {
  width: 100%;
  height: auto;
}
.contact-location-1-2 {
  flex: 1;
  text-align: left;
  padding-left: 20px;
}

.contact-location-1-2 h4 {
  font-size: 20px;
  margin: 0px 0px 14px 0px;
  font-family: "Dosis", sans-serif;
  color: #0480c4;
}
.contact-location-1-2 p {
  color: #000 !important;
  font-family: "Poppins", sans-serif;
}

.blog-title-two-col {
  margin: 20px 0px 0px 0px;
  display: flex;
  width: 100%;
}
.blog-title-two-col-1 {
  flex: 1;
}

.blog-title-two-col h4 {
  color: #000;
  font-family: "Dosis", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
}

.blog-title-two-col h6 {
  line-height: 24px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.opportunities-card {
  background: #f8f8f8;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-bottom: 50px;
}
.opportunities-card h3 {
  color: #000;
  font-family: "Dosis", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}
.opportunities-card p {
  line-height: 24px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.opportunities-card-link {
  text-align: right;
  margin: 10px 0px;
}
@media screen and (max-width: 1170px) {
  .contact-location-1 {
    width: 45%;
  }
}
@media screen and (max-width: 830px) {
  .contact-location-1 {
    width: 45%;
  }
  h1,
  h2 {
    font-size: 40px;
    margin: 0px 0px 20px 0px;
  }
  .inner-banner h1,
  .inner-banner h2,
  .inner-section-1-wrap h1,
  .inner-section-1-wrap h2 {
    font-size: 40px;
  }
  .two-col-main {
    flex-wrap: wrap;
  }
  .two-col-1 {
    width: 100%;
    margin-bottom: 20px;
  }

  .button-2 {
    padding: 6px 14px;
    font-size: 16px;
  }
  .contact-location-1 {
    width: 45%;
    margin-bottom: 20px;
  }
  .contact-location-1-1 {
    width: 40px;
  }
  .inner-banner {
    margin: 60px 0px 0px 0px;
  }
}

@media screen and (max-width: 560px) {
  h1,
  h2 {
    font-size: 30px;
    margin: 0px 0px 20px 0px;
  }
  .inner-banner h1,
  .inner-banner h2,
  .inner-section-1-wrap h1,
  .inner-section-1-wrap h2 {
    font-size: 30px;
  }
  .breadcrumb a {
    margin: 0px 5px;
    font-size: 12px;
  }
  .inner-section-1-wrap p {
    line-height: 22px;
    font-size: 15px;
  }
  .inner-banner p {
    line-height: 20px;
    font-size: 14px;
  }
  .button-2 {
    padding: 6px 14px;
    font-size: 16px;
  }
  .contact-location-1 {
    width: 100%;
    margin-bottom: 20px;
  }
  .contact-location-1-2 h4 {
    font-size: 18px;
    margin: 0px 0px 14px 0px;
    font-family: "Dosis", sans-serif;
    color: #0480c4;
  }
  .contact-location-1-1 {
    width: 30px;
  }
  .contact-location-1-2 {
    padding-left: 10px;
  }
  .contact-flex-1 {
    width: 100%;
    padding-right: 0%;
  }
}
