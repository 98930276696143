.section-1-bg {
  padding: 20px 0px;
}
.section-1-2-wrap {
  margin: 0px 0px 30px 0px;
}
.section-1-2-img {
  margin: 20px 0px 10px 0px;
}
.section-1-2-img img {
  height: 40px;
  width: auto;
}
.video-1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  min-height: 250px;
}
.section-1-wrap {
  display: flex;
  width: 100%;
}
.section-1-1 {
  width: 500px;
}

.section-1-2 {
  flex: 1;
  padding-left: 5%;
}
.section-1-wrap p {
  line-height: 24px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
h1 {
  font-family: "Dosis", sans-serif;
  color: #999;
  font-size: 50px;
  font-weight: 400;
}
.blue-color {
  color: #0480c4;
}
.black-color {
  color: #000000;
}

.section-1-1 h1 {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 200px;
}

.section-2-wrap {
  display: flex;
  width: 100%;
}
.section-2-1 {
  width: 50%;
  padding: 80px 20px;
  box-sizing: border-box;
  background-color: #0480c4;
  display: flex;
  justify-content: flex-end;
}
.section-2-1-textwrap {
  width: 500px;
  /* margin: auto; */
  margin: auto 0;
  margin-right: 15%;
}

.section-2-2 {
  flex: 1;
  padding-left: 0%;
}
.section-2-2 img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.button-1 {
  padding: 8px 20px;
  font-size: 20px;
  display: inline-block;
  border: 1px solid #fff;
  color: #fff;
  text-decoration: none;
  font-family: "Dosis", sans-serif;
  cursor: pointer;
}
.button-2 {
  padding: 8px 20px;
  font-size: 20px;
  display: inline-block;
  border: 1px solid #0480c4;
  color: #0480c4;
  text-decoration: none;
  font-family: "Dosis", sans-serif;
  cursor: pointer;
}

.button-3 {
  padding: 8px 20px;
  font-size: 20px;
  display: inline-block;
  border: 1px solid #0480c4;
  background-color: #0480c4;
  color: #fff;
  text-decoration: none;
  font-family: "Dosis", sans-serif;
  cursor: pointer;
  width: auto;
  display: inline-block;
}

h2 {
  font-family: "Dosis", sans-serif;
  color: #fff;
  font-size: 50px;
  font-weight: 400;
  margin: 0px 0px 20px 0px;
}

.section-2-wrap p {
  line-height: 30px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  margin: 0px 0px 20px 0px;
}

.section-3-1-textwrap {
  margin-left: 15% !important;
  margin-right: 0% !important;
}

.section-3-1 {
  background-color: #fcf9f2 !important;
  justify-content: flex-start !important;
}
.section-3-1 h2,
.section-3-1 p {
  color: #000 !important;
}
.section-3-1 .button-1 {
  border: 1px solid #0480c4 !important;
  color: #0480c4 !important;
}

.section-3-bg {
  background-image: url("../images/section-3-bg.png");
  background-size: cover;
  padding: 50px 0px;
}
.section-3-bg h2 {
  color: #999999;
}

.section-5-bg {
  padding: 50px 0px;
}
.section-5-bg h2 {
  color: #999;
}
.m-b-1 {
  margin-bottom: 20px !important;
}
.line-1 {
  margin: 50px 0px;
  height: 1px;
  background-color: #a4a4a4;
  width: 100%;
}
.line-2 {
  margin: 50px 0px 0px 0px;
  height: 1px;
  background-color: #a4a4a4;
  width: 100%;
}
.section-5-wrap {
  display: flex;
  width: 100%;
}

.section-5-1 {
  width: 50%;
  padding-right: 5%;
  margin: auto;
}
.section-5-2 {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-left: 5%;
  border-left: 1px solid #ccc;
}
.section-5-card {
  width: 45%;

  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
  padding: 14px;
  margin: 0px 0px 20px 0px;
}
.section-5-card:nth-child(1),
.section-5-card:nth-child(4) {
  background-color: #e6f6ff;
}

.section-5-card:nth-child(2),
.section-5-card:nth-child(3) {
  background: #0480c4 !important;
  color: #fff;
}

.section-5-card:nth-child(3) h5,
.section-5-card:nth-child(3) p,
.section-5-card:nth-child(2) h5,
.section-5-card:nth-child(2) p {
  color: #fff;
}

/* .section-5-card:nth-child(4) {
  background: #0480c4 !important;
  color: #fff;
}
.section-5-card:nth-child(4) h5,
.section-5-card:nth-child(4) p {
  color: #fff;
} */

.section-5-link {
  text-align: right;
  padding: 10px 0px 0px 0px;
  border-top: 1px solid #ccc;
  margin: 10px 0px 0px 0px;
}
.section-5-link img {
  height: 30px;
  width: auto;
}
.section-5-card h5 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #000;
  line-height: 20px;
}
.section-5-card p {
  color: #969696;
  font-size: 14px;
  line-height: 20px;
  margin: 0px !important;
}

.section-5-1 h2 {
  margin: 14px 0px;
  color: #000;
  font-family: "Dosis", sans-serif;
}
.section-5-1 p {
  color: #000 !important;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  margin: 30px 0px;
}

@media screen and (max-width: 830px) {
  h1,
  h2 {
    font-size: 40px;
    margin: 0px 0px 20px 0px;
  }

  .section-1-wrap,
  .section-2-wrap,
  .three-col-main,
  .section-5-wrap {
    flex-wrap: wrap;
  }
  .section-1-1,
  .section-1-2,
  .section-2-1,
  .section-2-2 {
    width: 100%;
    flex: inherit;
  }
  .section-1-2 {
    padding-left: 0px;
  }

  .section-2-1 {
    order: 2;
  }
  .section-2-2 {
    order: 1;
  }
  .section-2-1-textwrap {
    width: 100%;

    margin-right: 0;
  }
  .section-3-1-textwrap {
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
  .three-col-1 {
    width: 100%;
  }
  .section-5-1 {
    width: 100%;
    padding-right: 0px;
    margin: auto;
  }
  .section-5-2 {
    width: 100%;
  }

  .section-5-2 {
    border-left: 0px solid #ccc;
    padding-left: 0px;
    margin-top: 20px;
  }
  .line-1 {
    margin: 30px 0px;
  }
  .card-sec h3 {
    font-size: 24px;
  }
  .button-1 {
    padding: 6px 10px;
    font-size: 16px;
  }
  .carousel-caption h3 {
    font-size: 40px;
  }
  .services-card2-1 {
    width: 100%;
  }
  .services-card2-section .services-card2-1:nth-child(even) {
    margin-left: 0%;
  }
  .services-card2-1 h3 {
    font-size: 24px;
  }
}

@media screen and (max-width: 560px) {
  .carousel-caption h3 {
    font-size: 24px;
  }
  h1,
  h2 {
    font-size: 30px;
    margin: 0px 0px 20px 0px;
  }
  .section-5-card {
    width: 100%;
  }
  .line-1 {
    margin: 20px 0px;
  }
  .card-sec h3 {
    font-size: 22px;
  }
  .section-2-wrap p {
    font-size: 16px;
    line-height: 24px;
  }
  .card-sec p {
    color: #000 !important;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
  }
  .button-1 {
    padding: 6px 10px;
    font-size: 14px;
  }

  .button-3 {
    padding: 6px 14px;
    font-size: 16px;
  }
  .button-2 {
    padding: 6px 14px;
    font-size: 16px;
  }
}
