.carousel-inner img {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  object-position: center;
}

.carousel-caption {
  bottom: 100px !important;
  top: auto !important;
}

.banner-staic-img {
  position: relative;
}
.banner-staic-img img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
}

.banner-staic-img video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
}
