.services-section-wrap {
  display: flex;
}
.services-section-text1 {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 140px;
}
.wrap-2 {
  width: 100%;
}
.wrap-2 p {
  text-align: left;
  margin: 0px 0px 15px 0px;
}
.services-section-1 {
  width: 500px;
}
.services-section-2 {
  flex: 1;
  padding-left: 5%;
}
.services-section-wrap p {
  line-height: 24px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.services-section-2-wrap {
  background-color: #e6f6ff;
  padding: 20px 20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
}
.services-section-2-wrap h3 {
  font-family: "Dosis", sans-serif;
  font-size: 30px;
  font-weight: 400;
  border-bottom: 1px solid #ccc;
  padding: 0px 0px 10px 0px;
  margin: 0px 0px 16px 0px;
}
.services-section-2-wrap h6 {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
}

.m-three-col-main {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 0px;
}
.m-three-col-1 {
  width: 30%;
  border-radius: 10px;
  background: #e6f6ff;
  padding: 20px;

  /* height: fit-content; */
  color: #0480c4;
  margin-top: 40px;
}
.m-three-col-1 h4 {
  font-family: "Dosis", sans-serif;
  font-weight: 400;
  font-size: 30px;
}
.m-three-col-1 p {
  font-family: "Poppins", sans-serif;
  margin: 20px 0px;
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
}
.m-three-col-1 a {
  border: 1px solid #0480c4;
  background-color: #0480c4;
  padding: 8px 20px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
}
.inner-section-3-bg {
  padding: 30px 0px;
}
.margin-top-1 {
  margin-top: -0px !important;
  height: fit-content !important;
  padding-bottom: 30px;
}

.m-three-col-main .m-three-col-1:nth-child(odd) {
  background: #0480c4 !important;
  color: #fff;
}
.m-three-col-main .m-three-col-1:nth-child(odd) a {
  color: #0480c4;
  border-color: #fff !important;
  background-color: #fff;
}

.ms-content-list {
  border-left: 1px solid #ccc;
  padding: 0px 0px 20px 30px;
  position: relative;
  color: rgba(0, 0, 0, 0.5);
}
.ms-content-list::after {
  content: "";

  position: absolute;
  left: -11px;
  top: 0px;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  border: 4px solid #ccc;
  background-color: #fff;
}
.ms-content-list:hover::after {
  border: 4px solid #0480c4;
}
.ms-content-list:hover p {
  color: #0480c4;
}

.ms-content-list h4 {
  font-family: "Dosis", sans-serif;
  font-size: 30px;
  margin: -10px 0px 10px 0px;
  padding: 0px;
  color: #007ec3;
}
.ms-content-list h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin: 0px 0px 10px 0px;
  padding: 0px;
  color: #070047;
  font-size: 18px;
}
.ms-content-list p {
  font-size: 18px;
  color: #000;
}

/* .ms-content-list p:hover {
  color: #0480c4;
  cursor: pointer;
} */

.ms-content-list:last-child {
  border: 0px;
}

.breadcrumb {
  text-align: center;
  width: 100%;
  display: block;
}
.breadcrumb a {
  color: #000;
  margin: 0px 10px;
  display: inline-block;
  text-decoration: none;
}
.breadcrumb a:before {
  content: ">> ";
}
.breadcrumb a:hover {
  color: #0480c4;
}

@media screen and (max-width: 830px) {
  .services-section-wrap {
    flex-wrap: wrap;
  }
  .services-section-2 {
    flex: inherit;
    padding-left: 0;
    width: 100%;
  }
  .services-section-1 {
    width: 100%;
  }
  .services-section-2-wrap h3 {
    font-size: 24px;
  }
}

@media screen and (max-width: 560px) {
  .m-three-col-1 {
    width: 100%;
    margin-bottom: 20px;
  }
  .ff-1 {
    width: 100%;
  }
}
