.navbar {
  display: flex;
  justify-content: space-between;

  padding: 0px;
  color: #fff;
  width: 100%;
  position: fixed !important;
  z-index: 99999;
  top: 0px;
}

.navbar {
  background-color: transparent;
  transition: background-color 0.3s ease; /* Optional: Add a smooth transition effect */
  /* Add other styles for your header */
}

.navbar.scrolled {
  background-color: #fff; /* Change this to your desired background color */
  /* Add other styles for the scrolled state of your header */
  -webkit-box-shadow: 0px 6px 6px -5px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 6px 6px -5px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 6px 6px -5px rgba(0, 0, 0, 0.5);
}

.logo {
  /* Add your logo styles */
  width: 100%; /* Initial width */
  transition: width 0.3s ease; /* Optional: Add a smooth transition effect */
}

.logo.scrolled {
  width: 70% !important; /* Reduced width when scrolled */
}

.navbar-brand {
  font-size: 1.5rem;
}

.menu-toggle {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  background-color: #000;
}

.navbar-menu {
  display: flex;
  list-style: none;
  margin: 0px auto;
  padding: 0px;
  width: 100%;
  justify-content: space-around;
  margin: auto;
  position: relative;
  max-width: 1280px;
}

.navbar-menu.open {
  /* display: block; */
}

.navbar-menu li {
  /* position: relative; */
  margin: auto;
  font-weight: 500;
  font-size: 20px;
  font-family: "Dosis", sans-serif;
}

.navbar-menu li a,
.navbar-menu li button {
  display: block;
  padding: 0px;
  color: #000;
  text-decoration: none;
}

.navbar-menu .submenu .submenu-link,
.navbar-menu .dropdown .dropdown-link {
  cursor: pointer;
}

/* Dropdown menu styles */
.navbar-menu .dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  color: #000;
  list-style: none;
  padding: 0;
  margin: 0;
  top: 100%;
  left: 0;
  width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-menu li {
  text-align: left;
}

.dropdown-menu li a {
  padding: 0.5rem 1rem;
  display: block;
  color: #a4a4a4;
  text-decoration: none;
  border-bottom: 1px solid #ccc;
}
.dropdown-menu li:last-child a {
  border: 0px;
}

.dropdown-menu li a:hover {
  /* background-color: #f0f0f0; */
  color: #1b1548;
}

.logo-main {
  width: 150px;
  padding: 0px 10px;
}
.logo-main img {
  width: 100%;
  height: auto;
}
/* Hover effect for desktop */
@media (min-width: 768px) {
  .navbar-menu .submenu:hover .megamenu,
  .navbar-menu .dropdown:hover .dropdown-menu {
    display: block;
  }

  .megamenu {
    display: none;
  }
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .navbar-menu {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .navbar-menu.open {
    display: flex;
    height: 60vh;
    overflow: auto;
  }

  .navbar-menu li {
    text-align: center;
  }

  .megamenu,
  .dropdown-menu {
    position: static;
    box-shadow: none;
    padding: 0;
    width: 100%;
  }

  .navbar {
    flex-wrap: wrap;
  }
}

.mobile-menu {
  background-color: #fff;
  position: fixed;
  top: 0px;
  display: none;
  width: 100%;
  z-index: 999;
}
.mobile-wrap {
  display: flex;
  width: 96%;
  margin: 0 auto;
  flex-wrap: wrap;
}
.mobile-1 {
  width: 100px;
}
.mobile-1 img {
  width: 100%;
  height: auto;
  padding: 6px;
}
.mobile-2 {
  flex: 1;
  text-align: right;
  margin: auto;
}
.mobile-3 {
  width: 100%;
  text-align: right;
}
.mobile-3-height {
  height: 50vh;
  overflow: auto;
}
@media screen and (max-width: 830px) {
  .mobile-menu {
    display: block;

    background-color: #fff;
    box-shadow: 0px 6px 6px -5px rgba(0, 0, 0, 0.5);
  }
  nav {
    display: none !important;
  }
  .mobile-navigation {
    text-align: left;
  }
  .mobile-navigation ul {
    margin: 0px 0px 0px 10px;
    padding: 0px;
    list-style: none;
  }
  .mobile-navigation ul li {
    margin: 0px 0px 0px 10px;
    padding: 0px;
    list-style: none;
    border-bottom: 1px solid #ccc;
  }
  .mobile-navigation ul li a {
    color: inherit;
    text-decoration: none;
    padding: 3px 5px;
    display: block;
  }
}
.mobile-menu-btn {
  border: 0px;
  padding: 5px 10px;
  background-color: #0480c4;
  color: #fff;
  width: auto;
  display: inline-block;
}
