.footer {
  background: #e6f6ff;
  padding: 20px 0px;
}
.footer-logo {
  text-align: center;
}
.footer-logo img {
  height: 100px;
  width: auto;
}
.footer-copy-wrap {
  display: flex;
  width: 100%;
  border-top: 1px solid #000;
  padding: 10px 0px 0px 0px;
}
.footer-copy-1 {
  flex: 1;
}
.footer-copy-2 {
  width: 80px;
  text-align: right;
}
.footer-copy-2 img {
  height: 20px;
  width: auto;
}
.footer-nav {
  padding: 25px 0px 25px 0px;
}
.footer-nav ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer-nav ul li {
  margin: 0px;
  padding: 0px;
  text-align: center;
  list-style: none;
  font-size: 20px;
}
.footer-nav ul li a {
  color: #000;
  cursor: pointer;
}
.footer-nav ul li a:hover {
  color: #0480c4;
}

.footer-new-wrap {
  display: flex;
  width: 100%;
  margin: 10px 0px 20px 0px;
}
.footer-new-1 {
  width: 32%;
}
.footer-new-2 {
  flex: 1;
}
.footer-new-3 {
  width: 32%;
}
.footer-new-wrap ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.footer-new-wrap ul li {
  margin: 0px;
  padding: 6px 0px;
  list-style: none;
}
.footer-new-wrap ul li a {
  color: #000;
  text-decoration: none;
}
.w-50-50 li {
  width: 45%;
}

.footer-new-wrap ul li a:hover {
  color: #0480c4 !important;
}

@media screen and (max-width: 830px) {
  .footer-nav ul li a {
    text-align: center;
    padding: 10px 0px;
  }
  .footer-copy-wrap {
    flex-wrap: wrap;
  }
  .footer-copy-1,
  .footer-copy-2 {
    flex: inherit;
    width: 100%;
    text-align: center;
    padding: 5px 0px;
  }
}

@media screen and (max-width: 560px) {
  .footer-new-wrap {
    flex-wrap: wrap;
  }
  .footer-new-1,
  .footer-new-2,
  .footer-new-3 {
    width: 100%;
    margin-bottom: 10px;
  }
}
