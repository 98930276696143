/* font-family: "Dosis", sans-serif;
font-family: "Poppins", sans-serif; */

.team-sec1-bg {
  padding: 5px 0px;
}
.team-sec1-wrap {
  display: flex;
  margin-bottom: 20px;
}
.team-sec1-1 {
  width: 300px;
  position: relative;
}
.team-sec1-1 img {
  width: 100%;
  height: auto;
}
.team-sec1-2 {
  flex: 1;
  padding-left: 50px;
  margin: auto;
}
.linkedin {
  width: 40px;
  position: absolute;
  right: -20px;
  top: 50%;
  margin-top: -20px;
}
.team-sec1-bg h5 {
  font-family: "Dosis", sans-serif;
  font-size: 30px;
  font-weight: 400;
  padding: 0px 0px 10px 0px;
}
.team-sec1-bg h6 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  padding: 0px 0px 10px 0px;
}

.team-sec1-bg p {
  font-family: "Poppins", sans-serif;
  margin: 10px 0px;
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
}

.team-sec1-bg .team-sec1-wrap:nth-child(even) .team-sec1-1 {
  order: 2;
}
.team-sec1-bg .team-sec1-wrap:nth-child(even) .team-sec1-2 {
  order: 1;
  padding-left: 0px;
  padding-right: 50px;
}
.team-sec1-bg .team-sec1-wrap:nth-child(even) .team-sec1-1 .linkedin {
  left: -20px;
}

.social-content-list {
  border-left: 1px solid #ccc;
  padding: 0px 0px 20px 30px;
  position: relative;
  color: rgba(0, 0, 0, 0.5);
}
.social-content-list::after {
  content: "";

  position: absolute;
  left: -11px;
  top: 0px;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  border: 2px solid #ccc;
  background-color: #fff;
}
.social-content-list h4 {
  font-family: "Dosis", sans-serif;
  font-size: 22px;
  margin: -10px 0px 10px 0px;
  padding: 0px;
}
.social-content-list h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin: 0px 0px 10px 0px;
  padding: 0px;
  font-size: 16px;
}
.social-content-list p {
  font-size: 16px;
}

.social-content-list:last-child {
  border: 0px;
}

.our-vendor-relationship-form-flex form {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-us-form-flex form {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.error {
  color: red;
  font-size: 0.875rem;
}

.success {
  background-color: green;
  font-size: 14px !important;
  margin-top: 10px;
  color: #fff !important;
  padding: 5px 10px !important;
  border-radius: 100px !important;
}

.filter-main-wrap {
  display: flex;
  width: 100%;
  margin: 20px 0px;
}
.filter-main-1 {
  flex: 1;
  padding: 0px 10px;
}
.filter-main-wrap select {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px 10px;
}
.filter-main-2 {
  width: 100px;
  padding: 0px 10px;
}
button {
  width: 100%;
  text-align: center;
  padding: 10px 10px;
  border: 0px;
}

.filter-main-footer-wrap {
  display: flex;
  width: 100%;
  margin: 14px 0px 0px 0px;
  background-color: #f0f0f0;
  padding: 7px 14px;
  border: 1px solid #d5d5d5;
  border-radius: 100px;
}
.filter-main-footer-wrap b {
  padding-right: 10px;
  color: #0480c4;
  font-size: 16px;
}
.filter-main-footer-1 {
  flex: 1;
  padding: 0px 10px;
  margin: auto;
}
.filter-main-footer-2 {
  width: 30%;
  padding: 0px 10px;
  margin: auto;
}
.filter-main-footer-3 {
  width: 100px;
  padding: 0px 0px;
  margin: auto;
  text-align: right;
}

.button-3-small {
  padding: 4px 10px;
  font-size: 14px;
  display: inline-block;
  border: 1px solid #0480c4;
  background-color: #0480c4;
  color: #fff;
  text-decoration: none;
  font-family: "Dosis", sans-serif;
  cursor: pointer;
  border-radius: 100px;
}
.contact-location-1-2 h5 {
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
  background: #1a1548;
  display: inline-block;
  padding: 5px 15px;
  color: #fff;
  border-radius: 100px;
}

@media screen and (max-width: 830px) {
  .filter-main-wrap {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 560px) {
  .filter-main-1 {
    flex: inherit;
    padding: 0px 10px;
    width: 100%;
    margin-bottom: 10px;
  }
}

.success-message {
  background-color: green;
  font-size: 14px !important;
  margin-top: 10px;
  color: #fff !important;
  padding: 5px 10px !important;
  border-radius: 100px !important;
}
